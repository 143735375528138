import React from "react"
import { useTranslation } from "react-i18next";

import Layout from "../components/layout"
import SEO from "../components/seo"

import './caseTemplate.scss';


export default ({ data }) => {
  const post = data.markdownRemark
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={post.frontmatter.title}/>
      <div className='casePage page'>
        <h1 className='casePage__heading'>
          {post.frontmatter.title}
        </h1>
        <div className="casePage__year">
          <span className='casePage__year__heading'>{t("portfolioCase.year")}</span> {new Date(post.frontmatter.date).getFullYear()}
        </div>
        <div className='casePage__typeOfProject'>
        <span className='casePage__typeOfProject__heading'>{t("portfolioCase.typeOfProject")}</span> {post.frontmatter.projectType}
        </div>
        <div className='casePage__roles'>
          <span className='casePage__roles__heading'>{t("portfolioCase.roles")}</span> {
            post.frontmatter.roles.map((role, i, array) => (
              <span className='casePage__roles__role'>
                {role}{i !== array.length - 1 ? ', ': ''}
              </span>
            ))
          }
        </div>
        <div className='casePage__techUsed'>
        <span className='casePage__techUsed__heading'>{t("portfolioCase.techUsed")}</span> {
            post.frontmatter.techUsed.map((tech, i, array) => (
              <span className='casePage__techUsed__tech'>
                {tech}{i !== array.length - 1 ? ', ' : ''}
              </span>
            ))
          }
        </div>
        <div
          className='casePage__body'
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      title
      techUsed
      roles
      projectType
      date
    }
  }
}
`